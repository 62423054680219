import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styles from './UnitList.scss';
import ProgressiveButton, {
  STATUS_STARTED,
  STATUS_ENDED,
  STATUS_NOT_STARTED,
  STATUS_FAILED
} from '../ProgressiveButton/ProgressiveButton';

function UnitItem({
  unit = {},
  downloadButton = false,
  downloadCallback,
  userId: loggedUser,
  isPrintViewSelected = false,
  offlineDownloadProgress
}) {
  let initialStatus = STATUS_NOT_STARTED;
  if (unit.userId && unit.userId.length > 0) {
    initialStatus = unit.userId.includes(loggedUser) ? STATUS_ENDED : STATUS_NOT_STARTED;
  }
  const [unitStatus, setUnitStatus] = useState(initialStatus);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (offlineDownloadProgress[unit?.uId] && Object.keys(offlineDownloadProgress[unit?.uId]).length > 0) {
      if (unit?.activities?.length > 0) {
        const successfulDownloadsCount = Object.values(offlineDownloadProgress[unit?.uId]).filter(
          status => status === 'success'
        ).length;
        const failureDownloadsCount = Object.values(offlineDownloadProgress[unit?.uId]).filter(
          status => status === 'failure'
        ).length;

        if (successfulDownloadsCount === unit.activities?.length) {
          setUnitStatus(STATUS_ENDED);
        } else if (failureDownloadsCount === unit.activities?.length) {
          setUnitStatus(STATUS_FAILED);
        } else {
          setUnitStatus(STATUS_STARTED);
          setPercent(Math.round((successfulDownloadsCount / unit.activities.length) * 100));
        }
      }
    }
  }, [JSON.stringify(offlineDownloadProgress)]);

  return (
    <div className={styles.unitItem}>
      <div className={styles.unitNameColumn}>
        <div className={styles.unitName}>{unit.levelName}</div>
      </div>
      {unit.size && (
        <div className={styles.sizeColumn}>
          <div className={styles.size}>{unit.size}</div>
        </div>
      )}
      <div className={styles.actionsColumn}>
        {downloadButton && (
          <ProgressiveButton
            initialStatus={unitStatus}
            percent={percent}
            startCallback={async () => {
              await downloadCallback(unit.uId, isPrintViewSelected);
            }}
          />
        )}
      </div>
    </div>
  );
}

UnitItem.propTypes = {
  unit: PropTypes.object,
  downloadButton: PropTypes.bool,
  downloadCallback: PropTypes.func,
  isPrintViewSelected: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  offlineDownloadProgress: PropTypes.object
};

export default compose(
  connect(
    ({ offlineContentPlayer }) => ({
      offlineDownloadProgress: offlineContentPlayer.offlineDownloadProgress
    }),
    {}
  )
)(UnitItem);
