import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@oup/shared-front-end/src/components/Button';
import { ICON_DOWNLOAD, ICON_LOADING, ICON_CHECK_CIRCLE, ICON_REFRESH } from '@oup/shared-front-end/src/svg/oup/index';
import withLocalizedContent from '../../language/withLocalizedContent';

export const STATUS_NOT_STARTED = 'not started';
export const STATUS_STARTED = 'started';
export const STATUS_ENDED = 'ended';
export const STATUS_FAILED = 'failed';

function ProgressiveButton({
  startCallback,
  initialStatus,
  labels,
  icons,
  percent = 0,
  localizedContent: { progressiveButton: content }
}) {
  const [currentStatus, setCurrentStatus] = useState();

  useEffect(() => {
    setCurrentStatus(initialStatus || STATUS_NOT_STARTED);
  }, [initialStatus]);

  const { labelNotStarted = null, labelStarted = null, labelEnded = null, labelFailed = null } = labels || {};
  const { iconNotStarted = null, iconStarted = null, iconEnded = null, iconFailed = null } = icons || {};

  let label;
  let icon;
  if (currentStatus === STATUS_STARTED) {
    label = labelStarted || content.default_label_started;
    label += ` - ${percent}%`;
    icon = iconStarted || { component: <ICON_LOADING /> };
  } else if (currentStatus === STATUS_ENDED) {
    label = labelEnded || content.default_label_ended;
    icon = iconEnded || { component: <ICON_CHECK_CIRCLE /> };
  } else if (currentStatus === STATUS_FAILED) {
    label = labelFailed || content.default_label_failed;
    icon = iconFailed || { component: <ICON_REFRESH /> };
  } else {
    label = labelNotStarted || content.default_label_not_started;
    icon = iconNotStarted || { component: <ICON_DOWNLOAD /> };
  }

  const handleButtonClick = async () => {
    if (currentStatus === STATUS_ENDED || currentStatus === STATUS_STARTED) {
      return;
    }
    setCurrentStatus(STATUS_STARTED);
    await startCallback();
  };

  return <Button variant="transparent" size="small" text={label} icon={icon} onClick={handleButtonClick} />;
}

ProgressiveButton.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  startCallback: PropTypes.func.isRequired,
  initialStatus: PropTypes.oneOf([STATUS_NOT_STARTED, STATUS_ENDED]),
  labels: PropTypes.object,
  icons: PropTypes.object,
  percent: PropTypes.number
};

export default withLocalizedContent('progressiveButton')(ProgressiveButton);
