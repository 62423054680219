import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import UnitItem from './UnitItem';
import withLocalizedContent from '../../language/withLocalizedContent.js';
// Styles
import styles from './UnitList.scss';
import Checkbox from '../Checkbox/Checkbox';

function UnitList({
  units = [],
  downloadButton = false,
  downloadCallback,
  localizedContent: { productDownload: offlineContent },
  userId
}) {
  const [isPrintViewSelected, setIsPrintViewSelected] = useState(false);
  return (
    <div className={styles.unitsContainer}>
      {units.map(unit => (
        <div className={styles.unitWrapper} key={unit.uid}>
          <UnitItem
            unit={unit}
            downloadButton={downloadButton}
            downloadCallback={downloadCallback}
            isPrintViewSelected={isPrintViewSelected}
            userId={userId}
          />
          {unit.hasAnyActivityWithPrintView && (
            <div className={styles.printViewCheckboxContainer}>
              <div className={styles.printViewCheckboxSubContainer}>
                <Checkbox
                  key={`${unit.uid}-checkbox`}
                  label="label"
                  labelHidden
                  value={isPrintViewSelected}
                  onChange={() => setIsPrintViewSelected(!isPrintViewSelected)}
                  disabled={false}
                />
                <span>{offlineContent.include_print_pages}</span>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

UnitList.propTypes = {
  units: PropTypes.array.isRequired,
  downloadButton: PropTypes.bool,
  downloadCallback: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  userId: PropTypes.string
};

export default compose(withLocalizedContent('productDownload'))(UnitList);
